<template>
  <div>
      <v-card v-for="division in tournament.publicDivisions" :key="division.id" class="ma-1">
        <v-toolbar dense color="color1 color1Text--text">
          {{division.name}}{{division.complete ? ' - Locked' : ''}}
          <v-spacer></v-spacer>
          <setting-toggle
            :dataId="division.id"
            propName="complete"
            :isDivisionProp="true"
            :fab="true"
            :icon="division.complete ? 'fas fa-lock-alt' : 'fas fa-lock-open-alt'"
          ></setting-toggle>
        </v-toolbar>
        <v-container class="pa-0">
          <v-row dense>
            <v-col class="text-right" cols="12" >
              <setting-toggle
                :dataId="division.id"
                :disabled="tournament.locked"
                propName="hide"
                :isDivisionProp="true"
                :jProp="true"
                :button="true"
                :true-label="'Show on menu'"
                :false-label="'Hide from menu'"
              ></setting-toggle>
            </v-col>
          </v-row>
        </v-container>
        <v-simple-table dense class="pb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="h in headers" :key="h.text">{{h.text}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-right">
                  {{division.teamCount}} Teams
                </th>
                <td>
                  <setting-toggle
                    :dataId="division.id"
                    :disabled="tournament.locked"
                    propName="showTeams"
                    :isDivisionProp="true"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                  ></setting-toggle>
                </td>
                <td>
                  <setting-toggle
                    :dataId="division.id"
                    :disabled="division.locked"
                    propName="hideSeed"
                    :isDivisionProp="true"
                    :jProp="true"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                    :invert="true"
                  ></setting-toggle>
                </td>
                <td colspan="3"></td>
              </tr>
              <tr v-for="(round, i) in division.days" :key="round.id" :class="{'mb-2': i === division.days.length - 1}">
                <th class="text-right">{{round.name}}</th>
                <td>
                  <setting-toggle
                    :dataId="round.id"
                    :disabled="round.locked"
                    propName="published"
                    :isRoundProp="true"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                  ></setting-toggle>
                </td>
                <td>
                  <setting-toggle
                    :dataId="division.id"
                    :disabled="true"
                    propName="hideSeed"
                    :isDivisionProp="true"
                    :jProp="true"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                    :invert="true"
                  ></setting-toggle>
                </td>
                <td>
                  <setting-toggle
                    :dataId="round.id"
                    :disabled="round.locked"
                    propName="hideTimes"
                    :isRoundProp="true"
                    iClass="shrink mt-0"
                    :invert="true"
                    :small='$vuetify.breakpoint.xsOnly'
                  ></setting-toggle>
                </td>
                <td>
                  <setting-toggle
                    :dataId="round.id"
                    :disabled="round.locked"
                    propName="noRefs"
                    :isRoundProp="true"
                    :jProp="true"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                    :invert="true"
                  ></setting-toggle>
                </td>
                <td>
                  <setting-toggle
                    :dataId="round.id"
                    propName="locked"
                    :isRoundProp="true"
                    :disabled="division.complete"
                    iClass="shrink mt-0"
                    :small='$vuetify.breakpoint.xsOnly'
                  ></setting-toggle>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import isDark from '@/classes/IsDark'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle.vue'

export default {
  data () {
    return {
      headers: [
        { text: '', sortable: false },
        { text: 'Publish', sortable: false },
        { text: 'Seeds', sortable: false },
        { text: 'Times', sortable: false },
        { text: 'Refs', sortable: false },
        { text: 'Lock', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['tournament', 'theme']),
    chipColor () {
      return isDark(this.theme.color2) ? 'color2 color2Text--text' : 'color2Text color2--text'
    }
  },
  components: {
    SettingToggle
  }
}
</script>

<style scoped>

</style>
