<template>
  <v-dialog
    v-model="open"
    scrollable
    persistent
    max-width="550px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Quick Settings</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            color="color3 color3Text--text"
            class="mr-2"
            small fab
            v-on="{ ...tt, ...d }"
          >
            <v-icon small>fas fa-cogs</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card color="grey lighten-3">
      <div>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Quick Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1 color1Text--text" @click.stop="open = false" fab small>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      </div>
      <v-card-text class="px-1" :class="{'px-0': $vuetify.breakpoint.xsOnly}">
        <quick-settings v-if="open"></quick-settings>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QuickSettings from './Index'
export default {
  data () {
    return {
      open: false
    }
  },
  components: {
    QuickSettings
  }
}
</script>

<style scoped>

</style>
